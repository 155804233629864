import ENVIRONMENT from './environment';

const { BASE_URL } = ENVIRONMENT;
// const CMS_URL = 'https://cms.agencysavage.com';

const apiRoutes = {
  // Auth
  USERS: `${BASE_URL}/auth/users`,
  USER_INFO: `${BASE_URL}/auth/user`,
  LOGIN: `${BASE_URL}/auth/login`,
  RESET_EMAIL: `${BASE_URL}/auth/password/email`,
  RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,

  // Modules
  ROLES: `${BASE_URL}/acl/roles`,
  PERMISSIONS: `${BASE_URL}/acl/permissions`,

  // Logs
  EMAIL_LOGS: `${BASE_URL}/logs/sent-emails`,

  // onboarding
  ONBOARDING: `${BASE_URL}/billing/onboarding`,
  ONBOARDING_STEPS: `${BASE_URL}/billing/onboarding-steps`,
  ONBOARDING_INSTRUCTIONS: 'https://cms.agencysavage.com/onboard-instructions',
  ONBOARDING_CLIENT: `${BASE_URL}/customer/onboarding`,
  // ONBOARDING_ITEMS: `${BASE_URL}/onboarding-items`,
  ONBOARDING_ORDERS: `${BASE_URL}/customer/orders`,

  // Orders
  ORDERS: `${BASE_URL}/billing/orders`,
  PAYMENT_METHOD: `${BASE_URL}/stripe/payment-methods`,
  PLAID: `${BASE_URL}/plaid/create_link_token`,
  PLAID_EXCHANGE: `${BASE_URL}/plaid/exchange`,
  PLAID_CLIENT_EXCHANGE: `${BASE_URL}/plaid/client-exchange`,

  // AIR TABLE
  ADS_PROJECTS: `${BASE_URL}/airtable/advertising-projects`,
  NOT_ADS_PROJECTS: `${BASE_URL}/airtable/not-advertising-projects`,

  RESEND_INVITE_DROPBOX: `${BASE_URL}/resend-invite/dropbox`,
  RESEND_INVITE_WRIKE: `${BASE_URL}/resend-invite/wrike`,
  RESEND_INVITE_TYPEFORM: `${BASE_URL}/resend-invite/type-form`,

  // ANALYTICS
  ANALYTICS_GOOGLE: `${BASE_URL}/proxy/google-api`,
  ANALYTICS_FB: `${BASE_URL}/facebook-analytics`,

  // BILLING
  BILLING: `${BASE_URL}/billing`,
  BILLING_REMOVE_PAYMENT: `${BASE_URL}/billing/remove-payment-method`,
  PAYMENTS: `${BASE_URL}/billing/charges`,
  SUBSCRIPTIONS: `${BASE_URL}/billing/subscriptions`,
  INVOICES: `${BASE_URL}/billing/invoices`,

  DASHBOARD: `${BASE_URL}/admin/dashboard`,
  DASHBOARD_COUNTER: `${BASE_URL}/dashboard-counters`,

  // CUSTOMERS
  CUSTOMERS: `${BASE_URL}/billing/customers`,
  STATS: `${BASE_URL}/billing/customer-stats`,

  // DISCOUNTS
  DISCOUNTS: `${BASE_URL}/billing/discounts`,

  // REQUESTS
  REQUESTS: `${BASE_URL}/requests/requests`,

  // CONTACTS
  CONTACTS: `${BASE_URL}/billing/contacts`,

  // RESOURCES
  RESOURCES: 'https://cms.agencysavage.com/resources/18',

  // PROJECTS
  COMMENTS: `${BASE_URL}/comments`,
  INBOX: `${BASE_URL}/comments/inbox`,
  PROJECTS: `${BASE_URL}/projects/projects`,
  TIME_LOG: `${BASE_URL}/projects/time-log`,
  TIME_LOG_REPORT: `${BASE_URL}/projects/time-log/report`,
  TIME_LOG_TOTAL: `${BASE_URL}/projects/time-log/sum`,
  PROJECT_TASKS: `${BASE_URL}/projects/tasks`,
  PRODUCTS: `${BASE_URL}/projects/services`,
  PRODUCT_PROCESS: `${BASE_URL}/projects/services-workflows`,

  // INTEGRATIONS
  INTEGRATIONS: `${BASE_URL}/settings/integrations`,
  INTEGRATION_STATUS: `${BASE_URL}/settings/integrations/status`,

  // SERVICES
  SERVICES: `${BASE_URL}/projects/services`,

  // BLOG
  POSTS: `${BASE_URL}/blog/posts`,
  POST_CATEGORIES: `${BASE_URL}/blog/categories`,
  POST_TAGS: `${BASE_URL}/blog/tags`,
  CMS: `${BASE_URL}/cms`,
  FORMS: `${BASE_URL}/forms/forms`,
  ANSWERS: `${BASE_URL}/forms/answers`,
  PUBLIC_ANSWERS: `${BASE_URL}/public-answers`,

  // // CMS
  // CMS: {
  //   LOGIN: `${CMS_URL}/auth/local`,
  //   DECKS: `${CMS_URL}/decks`,
  //   POSTS: `${CMS_URL}/posts`,
  //   TEMPLATES: `${CMS_URL}/templates`,
  //   DOCUMENTATIONS: `${CMS_URL}/documentations`,
  //   CAREERS: `${CMS_URL}/careers`,
  //   EBOOKS: `${CMS_URL}/ebooks`,
  //   EMPLOYEE_RESOURCES: `${CMS_URL}/employees`,
  //   PROTOCOLS: `${CMS_URL}/protocols`,
  //   RESOURCES: `${CMS_URL}/resources`,
  //   USERS: `${CMS_URL}/users`,
  //   ONBOARD_INSTRUCTIONS: `${CMS_URL}/onboard-instructions`,
  //   WRIKE_TRAININGS: `${CMS_URL}/wrike-trainings`,
  //   FORMS: `${BASE_URL}/type-form/forms`,
  //   SERVICES: `${BASE_URL}/billing/services`,
  // },

  // UPLOAD
  CREATE_PRE_SIGNED: `${BASE_URL}/create-pre-signed-url`,
  UPLOADS: `${BASE_URL}/uploads`,
};

export default apiRoutes;
