/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GET_FORMS_FULLFILLED, GET_FORMS_PENDING, GET_FORMS_REJECTED } from '../actions';

const forms = (state = { pending: false, data: [], error: null }, action: any) => {
  switch (action.type) {
    case GET_FORMS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case GET_FORMS_FULLFILLED:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_FORMS_REJECTED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default forms;
