import { message } from 'antd';

const asyncErrorHandler = (error: any) => {
  if (error?.errorFields) {
    message.error('Some fields are missing.');
    return;
  }

  const errors = error?.response?.data?.errors;
  const keys = Object.keys(errors ?? {});
  if (keys.length > 0) {
    const values = keys.map((key) => errors[key]);
    message.error(values[0]);
  } else {
    message.error(error?.response?.data?.message ?? error.message ?? 'Something went wrong!');
  }
};

export default asyncErrorHandler;
