import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getProjectTasks, setProjectTasksData, setProjectTasksError, setProjectTasksLoading } from './projectTasks-slice';

const { get } = api;

function* getProjectTasksSagaListener(action: any) {
  try {
    yield put(setProjectTasksLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_TASKS, action.payload);
    yield put(setProjectTasksData(res.data));
  } catch (error) {
    yield put(setProjectTasksError(error));
    asyncErrorHandler(error);
  }
}

function* projectTasksSaga(): Generator<StrictEffect> {
  yield takeLatest(getProjectTasks.type, getProjectTasksSagaListener);
}

export default projectTasksSaga;
