/* eslint-disable */
import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import groupBy from 'lodash/groupBy';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getAnalytics, setAnalyticsData, setAnalyticsError, setAnalyticsLoading } from './analytics-slice';

const { get } = api;

function* getAnalyticsSagaListener(action: any) {
  try {
    yield put(setAnalyticsLoading());
    const res: AxiosResponse = yield call(
      get,
      `${action.payload?.status ? apiRoutes.ANALYTICS_GOOGLE : apiRoutes.ANALYTICS_FB}`,
      action.payload,
    );

    const filteredData = res?.data?.data ?? res?.data;
    const groupedByCampaign = groupBy(filteredData, (data) => data?.Campaign_name ??  data?.campaign_name);
    const updatedArray = Object.keys(groupedByCampaign).map((key) => [key, groupedByCampaign[key]])
    .sort((a, b)=>  {
      if(a[0] < b[0]) { return -1; }
      if(a[0] > b[0]) { return 1; }
      return 0;
    });
    yield put(setAnalyticsData(updatedArray));
  } catch (error) {
    yield put(setAnalyticsError(error));
    asyncErrorHandler(error);
  }
}

function* analyticsSaga(): Generator<StrictEffect> {
  yield takeLatest(getAnalytics.type, getAnalyticsSagaListener);
}

export default analyticsSaga;
