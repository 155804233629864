import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getDiscounts, setDiscountsData, setDiscountsError, setDiscountsLoading } from './discounts-slice';

const { get } = api;

function* getDiscountsSagaListener(action: any) {
  try {
    yield put(setDiscountsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.DISCOUNTS, action.payload);
    yield put(setDiscountsData(res.data));
  } catch (error) {
    yield put(setDiscountsError(error));
    asyncErrorHandler(error);
  }
}

function* discountsSaga(): Generator<StrictEffect> {
  yield takeEvery(getDiscounts.type, getDiscountsSagaListener);
}

export default discountsSaga;
