/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const activeTaskSlice = createSlice({
  name: 'activeTask',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getActiveTask: (state, action) => {},
    setActiveTaskLoading: (state, action) => ({ ...state, loading: action.payload }),
    setActiveTaskData: (state, action) => ({ ...state, loading: false, data: action.payload }),
  },
});

export const { setActiveTaskData, getActiveTask, setActiveTaskLoading } = activeTaskSlice.actions;

export default activeTaskSlice.reducer;
