import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import { getLocalUser } from 'utils/auth';
import { AxiosResponse } from 'utils/interface';

import { getActiveLogs, setActiveLogsData, setActiveLogsError, setActiveLogsLoading } from './activeLogs-slice';

/**
 * worker saga
 */
const { get } = api;

export function* getActiveLogsSagaListener(): any {
  const user = getLocalUser();

  try {
    yield put(setActiveLogsLoading());
    const res: AxiosResponse = yield call(get, `${apiRoutes.TIME_LOG}`, {
      'filters[user.uuid][]': user?.uuid,
      'filters[status]': ['running', 'paused'],
    });
    yield put(setActiveLogsData(res?.data?.data));
  } catch (e) {
    yield put(setActiveLogsError(e));
  }
}

/**
 * watcher saga
 */
function* activeLogsSaga(): Generator<StrictEffect> {
  yield takeEvery(getActiveLogs.type, getActiveLogsSagaListener);
}

export default activeLogsSaga;
