import { createSlice } from '@reduxjs/toolkit';

export const discountsSlice = createSlice({
  name: 'discounts',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getDiscounts: () => {},
    setDiscountsLoading: (state) => ({ ...state, loading: true }),
    setDiscountsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setDiscountsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setDiscountsData, setDiscountsError, setDiscountsLoading, getDiscounts } = discountsSlice.actions;

export default discountsSlice.reducer;
