import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getClients, setClientsData, setClientsError, setClientsLoading } from './clients-slice';

const { get } = api;

function* getClientsSagaListener(action: any) {
  try {
    yield put(setClientsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.CUSTOMERS, action.payload);
    yield put(setClientsData(res.data));
  } catch (error) {
    yield put(setClientsError(error));
    asyncErrorHandler(error);
  }
}

function* clientsSaga(): Generator<StrictEffect> {
  yield takeEvery(getClients.type, getClientsSagaListener);
}

export default clientsSaga;
