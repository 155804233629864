import { createSlice } from '@reduxjs/toolkit';

export const timelogReportSlice = createSlice({
  name: 'timelogReport',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getTimelogReport: () => {},
    setTimelogReportLoading: (state) => ({ ...state, loading: true }),
    setTimelogReportData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setTimelogReportError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setTimelogReportData, setTimelogReportError, setTimelogReportLoading, getTimelogReport } =
  timelogReportSlice.actions;

export default timelogReportSlice.reducer;
