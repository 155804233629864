import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getProjects, setProjectsData, setProjectsError, setProjectsLoading } from './projects-slice';

const { get } = api;

function* getProjectsSagaListener(action: any) {
  try {
    yield put(setProjectsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PROJECTS, action.payload);
    yield put(setProjectsData(res.data));
  } catch (error) {
    yield put(setProjectsError(error));
    asyncErrorHandler(error);
  }
}
function* projectsSaga(): Generator<StrictEffect> {
  yield takeLatest(getProjects.type, getProjectsSagaListener);
}

export default projectsSaga;
