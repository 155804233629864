/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    data:
      localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined'
        ? JSON.parse(localStorage.getItem('user') ?? '{}')
        : null,
    error: null,
  },
  reducers: {
    getAuth: (state, action) => {},
    setAuthLoading: (state) => ({ ...state, loading: true, error: null }),
    setAuthData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setAuthError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setAuthData, setAuthError, setAuthLoading, getAuth } = authSlice.actions;

export default authSlice.reducer;
