import { createSlice } from '@reduxjs/toolkit';

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getInvoices: () => {},
    setInvoicesLoading: (state) => ({ ...state, loading: true }),
    setInvoicesData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setInvoicesError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setInvoicesData, setInvoicesError, setInvoicesLoading, getInvoices } = invoicesSlice.actions;

export default invoicesSlice.reducer;
