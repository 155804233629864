import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import { ListResponse } from 'types';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { InboxResponse } from '../types';
import { getInbox, setInboxData, setInboxError, setInboxLoading } from './inbox-slice';

const { get } = api;

function* getInboxSagaListener(action: any) {
  try {
    yield put(setInboxLoading());
    const res: AxiosResponse<ListResponse<InboxResponse>> = yield call(get, apiRoutes.INBOX, action.payload);
    yield put(setInboxData(res.data));
  } catch (error) {
    yield put(setInboxError(error));
    asyncErrorHandler(error);
  }
}

function* inboxSaga(): Generator<StrictEffect> {
  yield takeEvery(getInbox.type, getInboxSagaListener);
}

export default inboxSaga;
