import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import { logoutUser } from 'services/auth';
import { authHeaders } from 'utils/auth';

let headers: any = {};
headers['Content-Type'] = 'application/json';
headers.withCredentials = true;
headers.Accept = 'application/json';
headers['Access-Control-Allow-Origin'] = '*';

headers = authHeaders(headers);

const handleAuthError = (error: AxiosError) => {
  if (error?.response?.status === 401) {
    message.error('Your not authorized.');
    logoutUser();
  } else if (error?.response?.status === 503) {
    window.location.pathname = '/maintenance';
  }
  return null;
};

axios.defaults.withCredentials = true;

const getApi = (url: string, params = {}, header = {}) => {
  headers = { ...headers, ...header };
  return axios
    .get(url, { params, headers })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const postApi = (url: string, data = {}, header = {}) => {
  headers = { ...headers, ...header };
  return axios
    .post(url, data, { headers })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const deleteApi = (url: string, data = {}, params = {}, header = {}) => {
  headers = { ...headers, ...header };
  return axios
    .delete(url, { data, params, headers })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const patchApi = (url: string, data = {}, header = {}) => {
  headers = { ...headers, ...header };
  return axios
    .patch(url, data, { headers })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const putApi = (url: string, data = {}, header = {}) => {
  headers = { ...headers, ...header };
  return axios
    .put(url, data, { headers })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const apiRequests = {
  get: getApi,
  post: postApi,
  delete: deleteApi,
  patch: patchApi,
  put: putApi,
};
export default apiRequests;
