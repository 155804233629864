/* eslint-disable consistent-return */
import config from 'components/Layout/components/Sidebar/config';
import appRoutes from 'config/appRoutes';
import _ from 'lodash';

export const authHeaders = (headers: any): any => {
  const token = localStorage.getItem('token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

export const authError = (msg: string): any => {
  if (msg === 'Request failed with status code 401') {
    window.location.href = appRoutes.LOGIN;
  }
};

export const checkHasPermission = (type: string | undefined): boolean => {
  if (!type) return false;
  const userObj: any = JSON.parse(localStorage.getItem('user') || '{}');
  const permissions = _.get(userObj, 'permissions', []);

  const res = permissions.find((el: any) => el?.name === type);
  return !!res;
};

export const checkHasRole = (type: string | undefined): boolean => {
  if (!type) return false;
  const user: any = JSON.parse(localStorage.getItem('user') || '{}');
  if (user) {
    const roles = _.get(user, 'roles', []);
    const res = roles.find((i: any) => i?.name === type);
    return !!res;
  }
  return false;
};

export const checkIsAdmin: any = () => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  if (user) {
    const roles = _.get(user, 'roles', []);
    const res = roles.find((i: any) => i?.name === 'admin');
    return !!res;
  }
  return {};
};

export const checkIsClient: any = () => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  if (user) {
    const roles = _.get(user, 'roles', []);
    const res = roles.find((i: any) => i?.name === 'customer');
    return !!res;
  }
  return {};
};

export const getDefaultRoute = () => {
  const res = config.find((el) => checkHasPermission(el.permission) || checkHasRole(el.role));
  return res?.path ?? '/dashboard';
};

export const getLocalUser = () => JSON.parse(localStorage.getItem('user') ?? '{}');
export const getOnboardingLock = () => {
  return false;
  const user = getLocalUser();
  return !!user?.contact?.customer?.onboarding;
};

export const isAuthenticated = () => !!localStorage.getItem('user');

export const needsOnboarding = () => JSON.parse(localStorage.getItem('user') ?? '{}')?.status === 'onboarding';
