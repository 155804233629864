import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getPayments, setPaymentsData, setPaymentsError, setPaymentsLoading } from './payments-slice';

const { get } = api;

function* getPaymentsSagaListener(action: any) {
  try {
    yield put(setPaymentsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PAYMENTS, action.payload);
    yield put(setPaymentsData(res.data));
  } catch (error) {
    yield put(setPaymentsError(error));
    asyncErrorHandler(error);
  }
}

function* paymentSaga(): Generator<StrictEffect> {
  yield takeEvery(getPayments.type, getPaymentsSagaListener);
}

export default paymentSaga;
