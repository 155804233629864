import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getProducts, setProductsData, setProductsError, setProductsLoading } from './products-slice';

const { get } = api;

function* getProductsSagaListener(action: any) {
  try {
    yield put(setProductsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PRODUCTS, action.payload);
    yield put(setProductsData(res.data));
  } catch (error) {
    yield put(setProductsError(error));
    asyncErrorHandler(error);
  }
}

function* productsSaga(): Generator<StrictEffect> {
  yield takeEvery(getProducts.type, getProductsSagaListener);
}

export default productsSaga;
