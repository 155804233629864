import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getInvoices, setInvoicesData, setInvoicesError, setInvoicesLoading } from './invoices-slice';

const { get } = api;

function* getInvoicesSagaListener(action: any) {
  try {
    yield put(setInvoicesLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.INVOICES, action.payload);
    yield put(setInvoicesData(res.data));
  } catch (error) {
    yield put(setInvoicesError(error));
    asyncErrorHandler(error);
  }
}

function* invoicesSaga(): Generator<StrictEffect> {
  yield takeEvery(getInvoices.type, getInvoicesSagaListener);
}

export default invoicesSaga;
