/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import _ from 'lodash';

const PrivateRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const location = useLocation();
  let data: any = localStorage.getItem('user');
  data = JSON.parse(data);
  const path: any = location.pathname;

  // const userObj: any = JSON.parse(localStorage.getItem('user') || '{}');
  // const roles = _.get(userObj, 'roles', []);
  // const routeRoles = ROUTE_PERMISSIONS[path] || [];
  // const hasPermission = checkHasPermission(roles, routeRoles);

  // if (hasPermission === false) {
  // 	const defaultPath = DEFAULT_ROUTE_FOR_ROLES[roles[0]];
  // 	return <Redirect to={{ pathname: defaultPath || '/login' }} />;
  // }
  return (
    <Route
      {...rest}
      render={(props) =>
        data?.uuid || false ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
    />
  );
};

export default PrivateRoute;
