import { getLocalUser } from 'utils/auth';

const permissions = {
  ROLES: {
    READ: 'role_read',
    CREATE: 'role_create',
    UPDATE: 'role_update',
    DELETE: 'role_destroy',
  },
  USER: {
    READ: 'user_read',
    CREATE: 'user_create',
    UPDATE: 'user_update',
    DELETE: 'user_destroy',
  },
  LOGS: {
    READ: 'logs_read',
  },
  ONBOARDING: {
    READ: 'onboarding_read',
    UPDATE: 'onboarding_update',
  },
  CUSTOMERS: {
    READ: 'customer_read',
    UPDATE: 'customer_update',
    CREATE: 'customer_create',
    DELETE: 'customer_destroy',
  },
  CONTACTS: {
    READ: 'contact_read',
    UPDATE: 'contact_update',
    CREATE: 'contact_create',
    DELETE: 'contact_destroy',
  },
  EMAIL_LOG: {
    READ: 'email_log_read',
  },
  ORDERS: {
    READ: 'order_read',
    UPDATE: 'order_update',
    CREATE: 'order_create',
    DELETE: 'order_destroy',
  },
  REQUESTS: {
    READ: 'request_read',
    UPDATE: 'request_update',
    CREATE: 'request_create',
    DELETE: 'request_destroy',
  },
  RESOURCES: {
    READ: 'resources_read',
  },
  CMS: {
    READ: 'cms_read',
  },
  DASHBOARD: {
    READ: 'dashboard_read',
  },
  SERVICES: {
    READ: 'service_read',
    UPDATE: 'project_update',
    CREATE: 'project_create',
    DELETE: 'project_destroy',
  },
  PRODUCTS: {
    READ: 'product_read',
    UPDATE: 'product_update',
    CREATE: 'product_create',
    DELETE: 'product_destroy',
  },
  PROJECTS: {
    READ: 'project_read',
    UPDATE: 'project_update',
    CREATE: 'project_create',
    DELETE: 'project_destroy',
  },
  TASK: {
    READ: 'task_read',
    UPDATE: 'task_update',
    CREATE: 'task_create',
    DELETE: 'task_destroy',
  },
  CHARGE: {
    READ: 'charge_read',
    UPDATE: 'charge_update',
    CREATE: 'charge_create',
    DELETE: 'charge_destroy',
  },
  SUBSCRIPTION: {
    READ: 'subscription_read',
    UPDATE: 'subscription_update',
    CREATE: 'subscription_create',
    DELETE: 'subscription_destroy',
  },
  INTEGRATIONS: {
    READ: 'subscription_read',
  },
};

export default permissions;

export const superUsers = ['christopher@savageglobalent.com', 'santiago@savageglobalent.com', 'marcelo@savageglobalent.com'];

export const checkIsSuperUser = (email: string) => {
  if (!email) return false;
  return !!superUsers.find((el) => email === el);
};

export const isSuperUser = () => {
  const user = getLocalUser();
  return checkIsSuperUser(user?.email);
};
