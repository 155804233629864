/* eslint-disable */
const labels = {
  DASHBOARD: 'Dashboard',
  ACCOUNT: 'Account',
  CLIENTS: 'Clients',
  PEOPLE: 'People',
  ROLES: 'Roles',
  PAYMENTS: 'Payments',
  ANALYTICS: 'Analytics',
  KEYWORDS: 'Keywords',
  TIMELOG_REPORT: 'Timelog',
  LOGS: 'Logs',
  ONBOARDING: 'Onboarding',
  ORDERS: 'Orders',
  PROJECTS: 'Projects',
  EDITABLE_PROJECTS: 'Live table',
  TASKS: 'Tasks',
  PRODUCTS: 'Products',
  RESOURCES: 'Resources',
  CMS: 'CMS',
  CONTENT: 'Content',
  INBOX: 'Inbox',
  INTEGRATIONS: 'Integrations',
};

export default labels;
