/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, StrictMode, useEffect } from 'react';
import { Provider } from 'react-redux';

import OpenReplay from '@openreplay/tracker/cjs';
// @ts-ignore
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { Result } from 'antd';
import ENVIRONMENT from 'config/environment';
import LogRocket from 'logrocket';
import Routing from 'routes';
import store from 'store';
import useVersionControl from 'utils/useVersionControl';

import 'simplebar/dist/simplebar.min.css';
import 'swiper/swiper.min.css';

const rollbarConfig = {
  accessToken: ENVIRONMENT.REACT_APP_ROLLBAR_TOKEN,
  environment: ENVIRONMENT.NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
};
const tracker = new OpenReplay({
  projectKey: 'JHAAUUuTF2g6h9ySWub6',
});

interface AppProps {}

const App: FC<AppProps> = () => {
  useEffect(() => {
    if (ENVIRONMENT.NODE_ENV === 'production') tracker.start();

    window.addEventListener(
      'dragover',
      (e) => {
        e.preventDefault();
      },
      false,
    );
    window.addEventListener(
      'drop',
      (e) => {
        e.preventDefault();
      },
      false,
    );
  }, []);

  useVersionControl();

  return (
    <StrictMode>
      <RollbarProvider config={rollbarConfig}>
        <ErrorBoundary fallbackUI={() => <Result status="warning" title="Something went wrong. Please reload the page." />}>
          {/* @ts-ignore */}
          <Provider store={store}>
            <Routing />
          </Provider>
        </ErrorBoundary>
      </RollbarProvider>
    </StrictMode>
  );
};

export default App;

LogRocket.init('sgm/client-portal-bv72u');
