import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getTimelogReport, setTimelogReportData, setTimelogReportError, setTimelogReportLoading } from './timelogReport-slice';

const { get } = api;

function* getTimelogReportSagaListener(action: any) {
  try {
    yield put(setTimelogReportLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.TIME_LOG_REPORT, action.payload);
    yield put(setTimelogReportData(res.data));
  } catch (error) {
    yield put(setTimelogReportError(error));
    asyncErrorHandler(error);
  }
}

function* customerSaga(): Generator<StrictEffect> {
  yield takeEvery(getTimelogReport.type, getTimelogReportSagaListener);
}

export default customerSaga;
