/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BarChartOutlined,
  BarsOutlined,
  DollarOutlined,
  FileDoneOutlined,
  BarcodeOutlined,
  InboxOutlined,
  KeyOutlined,
  LinkOutlined,
  TableOutlined,
  DashboardOutlined,
  SafetyOutlined,
  SolutionOutlined,
  UserAddOutlined,
  ApiOutlined,
  PieChartOutlined,
  ProjectOutlined,
  TeamOutlined,
  UserOutlined,
  ClockCircleOutlined,
  EditOutlined,
  SettingOutlined,
  FormOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import appRoutes from 'config/appRoutes';
import labels from 'config/labels';
import permissions from 'config/permissions';
import roles from 'config/roles';
import { FC } from 'react';

export interface MenuItemType {
  path?: string;
  label: string;
  icon: FC;
  permission?: string;
  submenu?: MenuItemType[];
  role?: string;
  disableClose?: boolean;
  needsSuperUser?: boolean;
}

const config: MenuItemType[] = [
  {
    path: appRoutes.DASHBOARD,
    label: labels.DASHBOARD,
    icon: DashboardOutlined,
    permission: permissions.DASHBOARD.READ,
    disableClose: true,
  },

  {
    label: 'Advertising',
    icon: BarChartOutlined,
    role: roles.ADMIN,
    submenu: [
      {
        path: appRoutes.ANALYTICS,
        label: labels.ANALYTICS,
        icon: BarChartOutlined,
        role: roles.ADMIN,
      },
      {
        path: appRoutes.EDITABLE_PROJECTS,
        label: labels.EDITABLE_PROJECTS,
        icon: OrderedListOutlined,
        role: roles.ADMIN,
      },
    ],
  },
  // {
  //   label: 'Billing',
  //   icon: DollarOutlined,
  //   role: roles.ADMIN,
  //   submenu: [
  //     {
  //       path: appRoutes.PAYMENTS,
  //       label: labels.PAYMENTS,
  //       icon: DollarOutlined,
  //       role: roles.ADMIN,
  //     },
  //     {
  //       path: appRoutes.ORDERS,
  //       label: labels.ORDERS,
  //       icon: UnorderedListOutlined,
  //       permission: permissions.ORDERS.READ,
  //     },
  //   ],
  // },
  {
    label: 'CMS',
    icon: ProjectOutlined,
    role: roles.ADMIN,
    submenu: [
      {
        path: appRoutes.CONTENT,
        label: labels.CONTENT,
        icon: EditOutlined,
        role: roles.ADMIN,
      },
      {
        path: appRoutes.CMS,
        label: 'Resources',
        icon: ProjectOutlined,
        permission: permissions.CMS.READ,
      },
    ],
  },
  {
    path: appRoutes.ACCOUNT,
    label: labels.ACCOUNT,
    icon: UserOutlined,
    role: roles.CUSTOMER,
  },
  // {
  // 	path: appRoutes.DASHBOARD,
  // 	label: "Dashboard",
  // 	icon : HomeOutlined,
  // },
  // {
  // 	path: "/profile",
  // 	label: "Profile",
  // 	icon : IdcardOutlined,
  // },
  // {
  // 	path: appRoutes.USERS,
  // 	label: "Users",
  // 	icon : UserOutlined,
  // 	role: roles.ADMIN,
  // },
  {
    path: appRoutes.CLIENTS,
    label: labels.CLIENTS,
    icon: TeamOutlined,
    role: roles.ADMIN,
  },
  {
    path: appRoutes.CONTACTS,
    label: labels.PEOPLE,
    icon: UserAddOutlined,
    permission: permissions.CONTACTS.READ,
  },
  {
    path: appRoutes.TIME_LOG_REPORT,
    label: labels.TIMELOG_REPORT,
    icon: ClockCircleOutlined,
    role: roles.ADMIN,
  },

  // {
  //   path: appRoutes.ONBOARDING,
  //   label: labels.ONBOARDING,
  //   icon: FileProtectOutlined,
  //   permission: permissions.ONBOARDING.READ,
  // },
  {
    path: appRoutes.PROJECTS,
    label: labels.PROJECTS,
    icon: ProjectOutlined,
    permission: permissions.PROJECTS.READ,
  },
  {
    path: appRoutes.TASKS,
    label: labels.TASKS,
    icon: BarsOutlined,
    permission: permissions.TASK.READ,
  },
  {
    path: appRoutes.PRODUCTS,
    label: labels.PRODUCTS,
    icon: BarcodeOutlined,
    permission: permissions.PRODUCTS.READ,
  },
  {
    path: appRoutes.FORMS,
    label: 'Forms',
    icon: FormOutlined,
    role: roles.ADMIN,
  },
  {
    path: appRoutes.RESOURCES,
    label: labels.RESOURCES,
    icon: LinkOutlined,
    permission: permissions.RESOURCES.READ,
  },
  {
    path: appRoutes.INBOX,
    label: labels.INBOX,
    icon: InboxOutlined,
    role: roles.ADMIN,
  },
  {
    label: 'Settings',
    icon: SettingOutlined,
    role: roles.ADMIN,
    submenu: [
      {
        path: appRoutes.ROLES,
        label: labels.ROLES,
        icon: UsergroupAddOutlined,
        permission: permissions.ROLES.READ,
      },
      {
        path: appRoutes.LOGS,
        label: labels.LOGS,
        icon: DatabaseOutlined,
        permission: permissions.EMAIL_LOG.READ,
      },
      {
        path: appRoutes.INTEGRATIONS,
        label: labels.INTEGRATIONS,
        icon: ApiOutlined,
        role: roles.ADMIN,
        needsSuperUser: true,
      },
    ],
  },

  // {
  // 	path: appRoutes.REQUESTS,
  // 	label: "Requests",
  // 	icon : ProjectOutlined ,
  // 	permission: permissions.REQUESTS.READ,
  // },
];

export default config;
export const getAllRoutes = () => {
  const routes: MenuItemType[] = [];

  config.forEach((el) => {
    if (el.submenu) {
      el.submenu.forEach((sub) => {
        routes.push(sub);
      });
    } else {
      routes.push(el);
    }
  });

  return routes;
};
