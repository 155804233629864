/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import-helpers/order-imports */
import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Loader from 'components/Loader';
import PrivateRoute from 'components/PrivateRoute';
import apiRoutes from 'config/apiRoute';
import appRoutes from 'config/appRoutes';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import 'styles/global.less';
import { getDefaultRoute } from 'utils/auth';
import Maintenance from 'components/Maintenance';
import ENVIRONMENT from 'config/environment';

const ClientPortalRoutes = React.lazy(() => import('./apps/modules-routes'));
const Login = React.lazy(() => import('auth/Login'));
const ForgotPassword = React.lazy(() => import('auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('auth/ResetPassword'));
const NotFound404 = React.lazy(() => import('auth/404'));
const ExpressCheckout = React.lazy(() => import('modules/expressCheckout/routes'));

const Routing: React.FC = () => {
  const inMaintenance = ENVIRONMENT.REACT_APP_MAINTENANCE;

  const fetchAndUpdateUser = async () => {
    try {
      const res = await apiRequests.get(apiRoutes.USER_INFO);
      if (res.data) {
        localStorage.setItem('user', JSON.stringify(res.data));
      }
    } catch (error) {
      asyncErrorHandler(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('user') && !inMaintenance) {
      fetchAndUpdateUser();
    }
  }, []);

  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route component={() => <Redirect to={getDefaultRoute()} />} exact path="/" />
          <Route component={Maintenance} path={appRoutes.MAINTENANCE} />
          {inMaintenance && <Route component={() => <Redirect to={appRoutes.MAINTENANCE} />} path="*" />}
          <Route component={ExpressCheckout} path={appRoutes.EXPRESS_CHECKOUT} />
          <Route component={Login} exact path={appRoutes.LOGIN} />
          <Route component={ForgotPassword} path={appRoutes.FORGOT_PASSWORD} />
          <Route component={ResetPassword} path={appRoutes.RESET} />
          <Route component={NotFound404} path="/404" />
          <PrivateRoute component={ClientPortalRoutes} path={`${appRoutes.CLIENT_PORTAL}/*`} />
          <Route component={() => <Redirect to="/404" />} path="*" />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default Routing;
