import { createSlice } from '@reduxjs/toolkit';

export const requestsSlice = createSlice({
  name: 'requests',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getRequests: () => {},
    setRequestsLoading: (state) => ({ ...state, loading: true }),
    setRequestsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setRequestsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setRequestsData, setRequestsError, setRequestsLoading, getRequests } = requestsSlice.actions;

export default requestsSlice.reducer;
