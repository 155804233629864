import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    getProjects: () => {},
    setProjectsLoading: (state) => ({ ...state, loading: true }),
    setProjectsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    clearProjectsData: () => initialState,
    setProjectsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setProjectsData, setProjectsError, setProjectsLoading, getProjects, clearProjectsData } = projectsSlice.actions;

export default projectsSlice.reducer;
