/* eslint-disable */
import clients from 'modules/client/store/clients-slice';
import contacts from 'modules/contact/store/contacts-slice';
import customers from 'modules/customer/store/customers-slice';
import inbox from 'modules/inbox/store/inbox-slice';
import emailLogs from 'modules/log/store/email-logs-slice';
import onboarding from 'modules/onboarding/store/onboarding-slice';
import onboardingOrders from 'modules/onboarding/store/onboardingOrders-slice';
import orders from 'modules/order/store/orders-slice';
import discounts from 'modules/payment/store/discounts-slice';
import invoices from 'modules/payment/store/invoices-slice';
import payments from 'modules/payment/store/payments-slice';
import subscriptions from 'modules/payment/store/subscriptions-slice';
import products from 'modules/product/store/products-slice';
import projects from 'modules/project/store/projects-slice';
import analytics from 'modules/analytics/store/analytics-slice';
import projectTasks from 'modules/project/store/projectTasks-slice';
import requests from 'modules/request/store/requests-slice';
import permissions from 'modules/role/store/permissions-slice';
import forms from 'modules/forms/store/reducer';
import answers from 'modules/forms/store/answers/reducer';
import roles from 'modules/role/store/roles-slice';
import content from 'modules/content/store/content-slice';
import users from 'modules/user/store/users-slice';
import timelogReport from 'modules/timelogReport/store/timelogReport-slice';

import activeLogs from './container/activeLogs/activeLogs-slice';
import activeTask from './container/activeTask/activeTask-slice';
import auth from './container/auth/auth-slice';
import globalLog from './container/globalLog/globalLog-slice';

export default {
  contacts,
  customers,
  onboarding,
  users,
  roles,
  inbox,
  auth,
  emailLogs,
  orders,
  invoices,
  permissions,
  onboardingOrders,
  requests,
  products,
  projects,
  analytics,
  projectTasks,
  payments,
  clients,
  subscriptions,
  discounts,
  activeTask,
  activeLogs,
  timelogReport,
  globalLog,
  content,
  forms, 
  answers
};
