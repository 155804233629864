// BASE APPS
const CLIENT_PORTAL = '';

const appRoutes = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET: '/reset',

  // BASE APPS
  CLIENT_PORTAL,

  // CLIENT PORTAL ROUTES
  DASHBOARD: `${CLIENT_PORTAL}/dashboard`,
  ROLES: `${CLIENT_PORTAL}/roles`,
  USERS: `${CLIENT_PORTAL}/users`,
  LOGS: `${CLIENT_PORTAL}/logs`,
  ORDERS: `${CLIENT_PORTAL}/all-orders`,

  ONBOARDING: `${CLIENT_PORTAL}/onboarding`,
  ONBOARDING_SUBMISSIONS: `${CLIENT_PORTAL}/onboarding/submissions`,
  ONBOARDING__requirements: `${CLIENT_PORTAL}/onboarding/requirements`,
  ONBOARDING__authentication: `${CLIENT_PORTAL}/onboarding/process`,
  ONBOARDING__orders: `${CLIENT_PORTAL}/onboarding/orders`,
  ONBOARDING__welcome: `${CLIENT_PORTAL}/onboarding/welcome`,
  ONBOARDING__information: `${CLIENT_PORTAL}/onboarding/information`,

  EXPRESS_CHECKOUT: `${CLIENT_PORTAL}/orders`,
  EXPRESS_CHECKOUT__success: `${CLIENT_PORTAL}/orders/success`,
  CLIENTS: `${CLIENT_PORTAL}/clients`,
  ACCOUNT: `${CLIENT_PORTAL}/account`,
  RESOURCES: `${CLIENT_PORTAL}/cms/sgm-preferred-referral-vendor-list`,
  CUSTOMERS: `${CLIENT_PORTAL}/customers`,
  CONTACTS: `${CLIENT_PORTAL}/people`,
  CONTENT: `${CLIENT_PORTAL}/content`,
  FORMS: `${CLIENT_PORTAL}/forms`,
  REQUESTS: `${CLIENT_PORTAL}/requests`,
  PAYMENTS: `${CLIENT_PORTAL}/payments`,
  INVOICES: `${CLIENT_PORTAL}/payments/invoices`,
  PRODUCTS: `${CLIENT_PORTAL}/products`,
  PROJECTS: `${CLIENT_PORTAL}/projects`,
  EDITABLE_PROJECTS: `${CLIENT_PORTAL}/editable-projects`,
  ANALYTICS: `${CLIENT_PORTAL}/analytics`,
  DISCOUNTS: `${CLIENT_PORTAL}/discounts`,
  COUPONS: `${CLIENT_PORTAL}/coupons`,
  INBOX: `${CLIENT_PORTAL}/inbox`,
  MAINTENANCE: `${CLIENT_PORTAL}/maintenance`,
  TASKS: `${CLIENT_PORTAL}/tasks`,
  SERVICES: `${CLIENT_PORTAL}/services`,

  INTEGRATIONS: `${CLIENT_PORTAL}/integrations`,
  INTEGRATIONS_GOOGLE: `${CLIENT_PORTAL}/integrations/google`,

  STATUS_REPORT: `${CLIENT_PORTAL}/status-report`,
  TIME_LOG_REPORT: `${CLIENT_PORTAL}/timelog-report`,
  CMS: `${CLIENT_PORTAL}/cms`,
};

export default appRoutes;

export const ROUTE_PERMISSIONS: any = {
  '/': ['admin'],
  '/client-portal/products': ['admin'],
  '/client-portal/customers': ['admin'],
  '/client-portal/client-view': ['collaborator'],
};

export const DEFAULT_ROUTE_FOR_ROLES: any = {
  admin: '/',
  collaborator: '/client-portal/client-view',
};
