import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getOnboarding, setOnboardingData, setOnboardingError, setOnboardingLoading } from './onboarding-slice';

const { get } = api;

function* getOnboardingSagaListener(action: any) {
  try {
    yield put(setOnboardingLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.ONBOARDING, action.payload);
    yield put(setOnboardingData(res.data));
  } catch (error) {
    yield put(setOnboardingError(error));
    asyncErrorHandler(error);
  }
}

function* onboardingSaga(): Generator<StrictEffect> {
  yield takeEvery(getOnboarding.type, getOnboardingSagaListener);
}

export default onboardingSaga;
