import { createSlice } from '@reduxjs/toolkit';

export const onboardingOrdersSlice = createSlice({
  name: 'onboardingOrders',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getOnboardingOrders: () => {},
    setOnboardingOrdersLoading: (state) => ({ ...state, loading: true }),
    setOnboardingOrdersData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setOnboardingOrdersError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setOnboardingOrdersData, setOnboardingOrdersError, setOnboardingOrdersLoading, getOnboardingOrders } =
  onboardingOrdersSlice.actions;

export default onboardingOrdersSlice.reducer;
