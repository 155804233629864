import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getUsers: () => {},
    setUsersLoading: (state) => ({ ...state, loading: true }),
    setUsersData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setUsersError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setUsersData, setUsersError, setUsersLoading, getUsers } = usersSlice.actions;

export default usersSlice.reducer;
