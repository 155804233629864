import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getUsers, setUsersData, setUsersError, setUsersLoading } from './users-slice';

const { get } = api;

function* getUsersSagaListener(action: any) {
  try {
    yield put(setUsersLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.USERS, action.payload);
    yield put(setUsersData(res.data));
  } catch (error) {
    yield put(setUsersError(error));
    asyncErrorHandler(error);
  }
}

function* userSaga(): Generator<StrictEffect> {
  yield takeEvery(getUsers.type, getUsersSagaListener);
}

export default userSaga;
