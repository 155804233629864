import { createSlice } from '@reduxjs/toolkit';

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getOrders: () => {},
    setOrdersLoading: (state) => ({ ...state, loading: true }),
    setOrdersData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setOrdersError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setOrdersData, setOrdersError, setOrdersLoading, getOrders } = ordersSlice.actions;

export default ordersSlice.reducer;
