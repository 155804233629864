import { createSlice } from '@reduxjs/toolkit';

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getOnboarding: () => {},
    setOnboardingLoading: (state) => ({ ...state, loading: true }),
    setOnboardingData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setOnboardingError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setOnboardingData, setOnboardingError, setOnboardingLoading, getOnboarding } = onboardingSlice.actions;

export default onboardingSlice.reducer;
