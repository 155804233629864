import { trimUrl } from 'utils/string.utils';

const ENVIRONMENT = {
  // BASE_URL: process.env.REACT_APP_API_URL || 'https://api.fullservicedigitalmarketing.com',
  // BASE_URL: process.env.REACT_APP_API_URL || 'https://api-dev.fullservicedigitalmarketing.com/api',
  // BASE_URL: process.env.REACT_APP_API_URL || 'https://lionshare-api.agencysavage.com/api',
  BASE_URL: process.env.REACT_APP_API_URL || 'https://lionshare-api-dev.agencysavage.com/api',
  NODE_ENV: process.env.NODE_ENV,
  isDevelopment: process.env.NODE_ENV === 'development',
  REACT_APP_STRIPE_PK: process.env.REACT_APP_STRIPE_PK ?? 'pk_live_f8v00K0pja9BYSuNzU9Vg6ke',
  REACT_APP_ROLLBAR_TOKEN: process.env.REACT_APP_ROLLBAR_TOKEN ?? '49fcb4272e644237ad2ba49a89c40800',
  REACT_APP_CALENDLY_LINK: process.env.REACT_APP_CALENDLY_LINK ?? 'https://calendly.com/yvikas9290/test',
  REACT_APP_GOOGLE_CLIENT_ID:
    process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '1008943756583-91kmjtaii8fectk50on1kbtsjrot76pk.apps.googleusercontent.com',
  REACT_APP_PLAID_ENV: process.env.REACT_APP_PLAID_ENV ?? 'sandbox',
  REACT_APP_MAINTENANCE: process.env.REACT_APP_MAINTENANCE === 'yes',
  REACT_APP_VERSION: '1.0.27',
  REACT_APP_MAGICBELL_API_KEY: process.env.REACT_APP_MAGICBELL_API_KEY ?? '6b94e6ce8b30e05f21ee213be00eda05ba8ddb4a',
  REACT_APP_UPLOADS_PATH:
    trimUrl(process.env.REACT_APP_UPLOADS_PATH) ?? 'https://lionshare-dev.s3.us-west-2.amazonaws.com/uploads',
};

export default ENVIRONMENT;
