import { createSlice } from '@reduxjs/toolkit';
import { ListResponse } from 'types';

import { InboxResponse } from '../types';

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState: {
    loading: false,
    data: null as ListResponse<InboxResponse> | null,
    error: null,
  },
  reducers: {
    getInbox: () => {},
    setInboxLoading: (state) => ({ ...state, loading: true }),
    setInboxData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setInboxError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setInboxData, setInboxError, setInboxLoading, getInbox } = inboxSlice.actions;

export default inboxSlice.reducer;
