/* eslint-disable */
import analyticsSaga from 'modules/analytics/store/analytics-saga';
import clientsSaga from 'modules/client/store/clients-saga';
import contactsSaga from 'modules/contact/store/contacts-saga';
import customerSaga from 'modules/customer/store/customers-saga';
import inboxSaga from 'modules/inbox/store/inbox-saga';
import emailLogsSaga from 'modules/log/store/email-logs-saga';
import onboardingSaga from 'modules/onboarding/store/onboarding-saga';
import onboardingOrdersSaga from 'modules/onboarding/store/onboardingOrders-saga';
import ordersSaga from 'modules/order/store/orders-saga';
import discountsSaga from 'modules/payment/store/discounts-saga';
import invoicesSaga from 'modules/payment/store/invoices-saga';
import paymentsSaga from 'modules/payment/store/payments-saga';
import subscriptionsSaga from 'modules/payment/store/subscriptions-saga';
import productsSaga from 'modules/product/store/products-saga';
import projectsSaga from 'modules/project/store/projects-saga';
import projectTasksSaga from 'modules/project/store/projectTasks-saga';
import requestsSaga from 'modules/request/store/requests-saga';
import permissionsSaga from 'modules/role/store/permissions-saga';
import rolesSaga from 'modules/role/store/roles-saga';
import usersSaga from 'modules/user/store/users-saga';
import formsSaga from 'modules/forms/store/sagas';
import answersSaga from 'modules/forms/store/answers/sagas';
import contentSaga from 'modules/content/store/content-saga';
import timelogReportSaga from 'modules/timelogReport/store/timelogReport-saga';
import { all, fork } from 'redux-saga/effects';

import activeLogsSaga from './container/activeLogs/activeLogs-sagas';
import authSaga from './container/auth/auth-sagas';

export default function* root() {
  yield all([
    fork(contactsSaga),
    fork(customerSaga),
    fork(onboardingSaga),
    fork(clientsSaga),
    fork(authSaga),
    fork(usersSaga),
    fork(paymentsSaga),
    fork(invoicesSaga),
    fork(permissionsSaga),
    fork(subscriptionsSaga),
    fork(rolesSaga),
    fork(emailLogsSaga),
    fork(ordersSaga),
    fork(onboardingOrdersSaga),
    fork(requestsSaga),
    fork(productsSaga),
    fork(projectsSaga),
    fork(analyticsSaga),
    fork(projectTasksSaga),
    fork(discountsSaga),
    fork(inboxSaga),
    fork(activeLogsSaga),
    fork(timelogReportSaga),
    fork(contentSaga),
    fork(formsSaga),
    fork(answersSaga),
  ]);
}
