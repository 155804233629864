/* eslint-disable consistent-return */
/* eslint-disable no-restricted-properties */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-cond-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import _ from 'lodash';

import { randomArrayItem } from './array.utils';
import { getLocalUser } from './auth';

const locale = 'en-us';

/**
 * Returns the current logged in user
 * @returns {string}
 */
export const getCurrentClientId = (): string => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  return _.get(user, 'preferences.current_client.wrike_id', null);
};

/**
 * Converts the input string to Snake Case
 * ex:
 * input : 'User Good'
 * output : 'user_good'
 * @param {string} string
 * @returns {string}
 */
export const convertToSnakeCase = (string: string): string => {
  if (!string) {
    return '';
  }
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
};

/**
 * Converts the snake case to title case
 * ex:
 * input : 'user_good'
 * output : 'User good'
 * @param {string} string
 * @returns {string}
 */
export const convertSnakeCaseToTitleCase = (string: string): string => {
  if (!string) {
    return '';
  }

  return string
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Converts the input number with commas
 * ex:
 * input : '12232'
 * output : '12,232'
 * @param {number} x
 * @returns {string}
 */
export const numberWithCommas = (x: any): string => x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Returns the appropriate color for the given status
 * @param {string} status
 * @returns {string}
 */
export const getStatusColor = (status: string): string => {
  const greenStatus = ['completed', 'online', 'success', 'paid', 'processed', 'sent', 'active', 'published', 'succeeded'];
  const yellowStatus = ['approved', 'draft'];
  const redStatus = ['needs_attention', 'failed', 'fail'];
  const purpleStatus = ['todo'];
  const cyanStatus = ['in_progress', 'scheduled'];
  const blueStatus = ['working_on_now'];
  const magentaStatus = ['waiting_approval', 'waiting_to_launch'];
  const goldStatus = ['pending', 'paused', 'to_review'];
  const greyStatus = ['cancelled', 'inactive', 'deleted'];

  let color = 'blue';

  if (greyStatus.find((el) => el === status)) {
    color = 'grey';
  }

  if (purpleStatus.find((el) => el === status)) {
    color = 'purple';
  }
  if (cyanStatus.find((el) => el === status)) {
    color = 'cyan';
  }
  if (blueStatus.find((el) => el === status)) {
    color = 'blue';
  }
  if (magentaStatus.find((el) => el === status)) {
    color = 'magenta';
  }
  if (goldStatus.find((el) => el === status)) {
    color = 'gold';
  }
  if (greenStatus.find((el) => el === status)) {
    color = 'green';
  }
  if (yellowStatus.find((el) => el === status)) {
    color = 'yellow';
  } else if (redStatus.find((el) => el === status)) {
    color = 'red';
  }
  return color;
};

/**
 * Returns the date difference from two give dates.
 * if the to is not defined it will default to current date
 * @param {string} from
 * @param {string} to
 * @returns {number}
 */
export const getDateDiff = (from: string, to?: string): number => {
  if (!from) return 0;
  const date1: any = new Date(from.replaceAll('-', '/'));
  const date2: any = to ? new Date(to.replaceAll('-', '/')) : Date.now();
  const diffTime = Math.abs(date1 - date2);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

/**
 * Converts the HTML List to string.
 * @param {string} data
 * @returns {string}
 */
export const convertHtmlListToText = (data: string): string => {
  data = data.replace('<ul>', '');
  data = data.replace('</ul>', '');
  data = data.replace('<li>', '');
  data = data.replaceAll('</li>', '');
  data = data.replaceAll('<li>', '\n');
  return data;
};

/**
 * return the month name for the give month in number
 * @param {number} monthNum
 * @returns {string}
 */
export const getMonthNameFromNumber = (value: number): string => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  return months[value - 1];
};

/**
 * coverts the Text to HTML List
 * @param {string} data
 * @returns {string}
 */
export const convertTextToHtmlList = (data: string) => {
  const lines = data.split('\n');
  let html = '<ul>';
  lines.forEach((line) => {
    html += `<li>${line}</li>`;
  });
  html += '</ul>';
  return html;
};

/**
 * Returns the random ANTD color
 * @returns {string}
 */
export const getRandomAntdTagColor = () => randomArrayItem(['cyan', 'purple', 'orange', 'geekblue', 'lime', 'magenta']);

/**
 * Converts any date format to US Date Format
 * @param {string} date
 * @returns {string}
 */
export const parseDateToUSFormat = (date: any) => {
  if (!date) {
    return '-';
  }
  return dayjs(date).format('M/DD/YYYY');
};

export const getAvatar = (custom?: any) => {
  const user = custom ?? getLocalUser();

  if (!user) {
    return null;
  }

  const nameArr = user?.name?.split(' ') ?? [];
  let name = '';
  const avatar = user?.avatar;

  if (avatar)
    return {
      type: 'img',
      value: avatar,
    };

  nameArr.map((val: string) => {
    name += val.charAt(0);
    return val;
  });
  return {
    type: 'text',
    value: name,
  };
};

/**
 * Parses the mentioned data and returns the information
 * @param {string} markup
 * @returns {string | null}
 */
export const mentionDataParser = (markup: string): string | null => {
  if (!markup) return null;
  // @ts-ignore
  return (
    markup
      ?.match(/\(([^()]*)\)/g)
      ?.map(($0) => $0.substring(1, $0.length - 1))
      ?.filter((el) => checkIfValidUUID(el)) ?? undefined
  );
};

/**
 * Parses the comment
 * @param {string} comment
 * @returns {string}
 */
export const mentionCommentParser = (comment: string): string => {
  const regex = /@\[(.*?)\]\(.*?\)/g;
  return comment.replaceAll(regex, '<span>@$1</span>');
};

/**
 * Check if the given input is valid UUID
 * @param {string} uuid
 * @returns {boolean}
 */
export const checkIfValidUUID = (uuid: string): boolean => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(uuid);
};

/**
 * Formats the give number to USD CURRENCY
 * @param {string} value
 * @returns {string}
 */
export const currencyFormatter = (value: any): string => {
  const val = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
  }).format(value);
  return val;
};

/**
 * Returns difference between two dates
 * @param {string} startDate
 * @param {string} endDate
 * @returns {number}
 */
export const getDateDifferenceInMinutes = (startDate: string, endDate = new Date(Date.now())): number => {
  return dayjs(endDate).diff(startDate) / 60000;
};

/**
 * Return the date difference in H:i format
 * @param {string} startDate
 * @param {string} endDate
 * @returns {string}
 */
export const getDateDifferenceInHrsMins = (startDate: string, endDate = new Date(Date.now())): string =>
  convertMinsToHrsMins(getDateDifferenceInMinutes(startDate, endDate));

/**
 * Converts to mins to H:i format
 * @param {number} mins
 * @returns {string}
 */
export const convertMinsToHrsMins = (mins: number): string => {
  const h = Math.floor(mins / 60);
  const m = mins % 60;
  const hStr = h < 10 ? `0${h}` : h; // (or alternatively) h = String(h).padStart(2, '0')
  const mStr = m < 10 ? `0${m}` : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${hStr}:${mStr}`;
};

/**
 * Converts Days Hours Mins to Mins
 * @param {number} d
 * @param {number} h
 * @param {number} m
 * @returns {number}
 */
export const convertDaysHrsMinsToMins = (d: number, h: number, m: number): number => d * 24 * 60 + h * 60 + m;

/**
 * Convert HH:MM to Mins
 * @param time {string}
 * @returns {number}
 */
export const convertHrsMinsToMins = (time: string): number => {
  const values = time.split(':');
  return parseInt(values[0], 10) * 60 + parseInt(values[1], 10);
};

/**
 * Returns offset timestamp
 * @param {string} mins
 * @returns {Date}
 */
export const getOffsetTimestamp = (mins: number): Date => {
  const offsetTime = new Date();
  offsetTime.setMinutes(offsetTime.getMinutes() + mins);

  return offsetTime;
};

/**
 * Return the query string value from the URL
 * @param {string} search
 * @param {string} key
 * @returns {string}
 */
export const getQueryValue = (search: string, key: string) => {
  const query = new URLSearchParams(search);
  return query.get(key);
};

export const ellipseText = (text: string | any, charsLimit = 60): string => {
  return text?.length > charsLimit ? `${text.substring(0, charsLimit)}...` : text;
};

export const convertArrToCommaSeparatedString = (array: any[]): string => {
  let finalString = '';

  array?.forEach((el: any, i: any) => {
    finalString += `${el}${i === array?.length - 1 ? '' : ', '}`;
  });

  return finalString;
};
export const minCharSpan = (text: string | any, minChars: number) => {
  if (!text) {
    text = '';
  }
  text = text?.toString();

  let finalText;

  const reqSpace = minChars - text?.length;
  if (reqSpace <= 0) {
    finalText = text;
  } else {
    let extraSpace = '';
    Array(reqSpace)
      .fill(0)
      .forEach(() => {
        extraSpace += '&nbsp;';
      });

    finalText = `${text}${extraSpace}`;
  }
  return finalText;
};
export const getHtmlFromArray = (value: any[]) => {
  let html = '<div>';
  value?.forEach((el) => {
    html += el;
  });
  html += '</div>';

  return html;
};

export const convertToSlug = (Text: string) => {
  if (!Text) return '';
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};
export const trimUrl = (url: string | undefined) => {
  if (!url) return null;

  if (url.slice(-1) === '/') {
    url = url.slice(0, -1);
  }

  return url;
};
