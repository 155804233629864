import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const projectTasksSlice = createSlice({
  name: 'projectTasks',
  initialState,
  reducers: {
    getProjectTasks: () => {},
    setProjectTasksLoading: (state) => ({ ...state, loading: true }),
    setProjectTasksData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    clearProjectTasksData: () => initialState,
    setProjectTasksError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setProjectTasksData, setProjectTasksError, setProjectTasksLoading, getProjectTasks, clearProjectTasksData } =
  projectTasksSlice.actions;

export default projectTasksSlice.reducer;
