import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import { AxiosResponse } from 'utils/interface';

import { getAuth, setAuthData, setAuthError, setAuthLoading } from './auth-slice';

/**
 * worker saga
 */
const { post, get } = api;

export function* getAuthSagaListener(action: any): any {
  try {
    yield put(setAuthLoading());
    yield call(post, apiRoutes.LOGIN, action.payload);
    const user: AxiosResponse = yield call(get, apiRoutes.USER_INFO);
    const userData = user.data;
    yield put(setAuthData(userData));

    if (userData) {
      localStorage.setItem('user', JSON.stringify(userData));
    }
  } catch (e) {
    yield put(setAuthError(e));
  }
}

/**
 * watcher saga
 */
function* authSaga(): Generator<StrictEffect> {
  yield takeEvery(getAuth.type, getAuthSagaListener);
}

export default authSaga;
