import { createSlice } from '@reduxjs/toolkit';

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getPayments: () => {},
    setPaymentsLoading: (state) => ({ ...state, loading: true }),
    setPaymentsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setPaymentsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setPaymentsData, setPaymentsError, setPaymentsLoading, getPayments } = paymentsSlice.actions;

export default paymentsSlice.reducer;
