import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getOnboardingOrders,
  setOnboardingOrdersData,
  setOnboardingOrdersError,
  setOnboardingOrdersLoading,
} from './onboardingOrders-slice';

const { get } = api;

function* getOnboardingOrdersSagaListener(action: any) {
  try {
    yield put(setOnboardingOrdersLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.ONBOARDING, action.payload);
    yield put(setOnboardingOrdersData(res.data));
  } catch (error) {
    yield put(setOnboardingOrdersError(error));
    asyncErrorHandler(error);
  }
}

function* onboardingOrdersSaga(): Generator<StrictEffect> {
  yield takeEvery(getOnboardingOrders.type, getOnboardingOrdersSagaListener);
}

export default onboardingOrdersSaga;
