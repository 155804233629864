import { createSlice } from '@reduxjs/toolkit';

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getSubscriptions: () => {},
    setSubscriptionsLoading: (state) => ({ ...state, loading: true }),
    setSubscriptionsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setSubscriptionsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setSubscriptionsData, setSubscriptionsError, setSubscriptionsLoading, getSubscriptions } =
  subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
