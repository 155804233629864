import { createSlice } from '@reduxjs/toolkit';

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getClients: () => {},
    setClientsLoading: (state) => ({ ...state, loading: true }),
    setClientsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setClientsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setClientsData, setClientsError, setClientsLoading, getClients } = clientsSlice.actions;

export default clientsSlice.reducer;
