/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getProducts: (payload?: any) => {},
    setProductsLoading: (state) => ({ ...state, loading: true }),
    setProductsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setProductsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setProductsData, setProductsError, setProductsLoading, getProducts } = productsSlice.actions;

export default productsSlice.reducer;
