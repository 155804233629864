import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getOrders, setOrdersData, setOrdersError, setOrdersLoading } from './orders-slice';

const { get } = api;

function* getOrdersSagaListener(action: any) {
  try {
    yield put(setOrdersLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.ORDERS, action.payload);
    yield put(setOrdersData(res.data));
  } catch (error) {
    yield put(setOrdersError(error));
    asyncErrorHandler(error);
  }
}

function* orderSaga(): Generator<StrictEffect> {
  yield takeEvery(getOrders.type, getOrdersSagaListener);
}

export default orderSaga;
