/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const globalLogSlice = createSlice({
  name: 'globalLog',
  initialState: {
    loading: false,
    data: null,
    trigger: 0,
    error: null,
  },
  reducers: {
    getGlobalLog: (state, action) => {},
    setGlobalLogLoading: (state, action) => ({ ...state, loading: action.payload }),
    setGlobalLogTrigger: (state) => ({ ...state, trigger: state.trigger + 1 }),
    setGlobalLogData: (state, action) => ({ ...state, loading: false, data: action.payload }),
  },
});

export const { setGlobalLogData, getGlobalLog, setGlobalLogTrigger, setGlobalLogLoading } = globalLogSlice.actions;

export default globalLogSlice.reducer;
