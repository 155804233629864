import React, { FC } from 'react';

import { Result } from 'antd';

interface MaintenanceProps {}

const Maintenance: FC<MaintenanceProps> = () => (
  <Result
    status="500"
    subTitle="I’m sure your sad because you can’t login, but don’t worry.
    We’ll be back online shortly with some awesome new updates

    Hang tight"
    title="Under Maintenance"
  />
);

export default Maintenance;
